export function useNavbarTheme() {
  const sectionList = ref<Array<HTMLElement | SVGElement>>([])

  const observer = ref<MutationObserver | null>(null)

  const onPageChange = () => {
    if (process.server)
      return

    if (!observer.value) {
      observer.value = new MutationObserver((_mutations) => {
        observer.value?.disconnect()
        onPageChange()
      })
    }

    const sections = document.querySelectorAll<HTMLElement | SVGElement>('[data-block-invert]:not([data-is-signature-page] [data-block-invert])')
    sectionList.value = Array.from(sections)
    sectionList.value.forEach((element) => {
      observer.value?.observe(element, {
        attributes: true,
        attributeFilter: ['data-block-invert'],
      })
    })
  }

  const coordArray = computed(() =>
    sectionList.value.map((element) => {
      return {
        element,
        bounding: useElementBounding(element),
      }
    }).sort((a, b) => a.bounding.y.value - b.bounding.y.value))

  const triggerPixelOffset = ref(120)

  const nuxtApp = useNuxtApp()

  const activeCoords = computed(() => {
    const index = coordArray.value.findIndex(element => element.bounding.y.value >= triggerPixelOffset.value)
    if (index <= 0) {
      // check if the last item is scrolled down
      const lastElement = coordArray.value.at(-1)
      if (lastElement && lastElement.bounding.y.value < triggerPixelOffset.value)
        return lastElement
      return null
    }
    if (index)
      return coordArray.value[index - 1]
  })

  const isInverted = computed(() => {
    return activeCoords.value?.element?.dataset.blockInvert === 'true'
  })

  nuxtApp.hook('page:finish', () => {
    onPageChange()
  })

  return {
    isInverted,
    activeCoords,
  }
}
